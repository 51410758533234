import React from 'react';
import { AppBar, Toolbar, IconButton, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../img/fb-high-resolution-logo-transparent.png';

const HomeNavBar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" component={Link} to="/">
          <img src={logo} alt="Logo" style={{ height: '25px' }} />
        </IconButton>
        <Button color="inherit" component={Link} to="/skills">Skills</Button>
        <Button color="inherit" component={Link} to="/projects">Projects</Button>
        <Button color="inherit" component={Link} to="/contact">Contact</Button>
      </Toolbar>
    </AppBar>
  );
};

export default HomeNavBar;