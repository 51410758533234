import React from 'react';
import { Container, Typography, Box, IconButton, Button } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Home = () => {
  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
        <Typography variant="h2" component="h1">
          FEDERICO BERTOLOTTI
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Full-stack developer
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" mt={15}>
          <IconButton
            component="a"
            href="https://www.linkedin.com/in/federico-bertolotti-68a697186/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn Profile"
          >
            <LinkedInIcon style={{ fontSize: 50 }} />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            href="/cv_20211220.pdf"
            download="Federico_Bertolotti_CV.pdf"
            style={{ marginLeft: 16 }}
          >
            Download CV
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
