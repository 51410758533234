import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider, Grid } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import CodeIcon from '@mui/icons-material/Code';
import WebIcon from '@mui/icons-material/Web';
import BuildIcon from '@mui/icons-material/Build';

const skills = {
  "Database": ["SQL", "PostgreSQL", "MongoDB"],
  "Backend": ["Python", "Flask", "Django", "Java", "Node.js"],
  "Frontend": ["React", "HTML/CSS/JS"],
  "Tools": ["Git", "GitLab", "Docker", "PostGIS", "QGIS"]
};

const categoryIcons = {
  "Database": <StorageIcon style={{ color: '#ff5722' }} />,
  "Backend": <CodeIcon style={{ color: '#3f51b5' }} />,
  "Frontend": <WebIcon style={{ color: '#4caf50' }} />,
  "Tools": <BuildIcon style={{ color: '#ffeb3b' }} />
};

const Skills = () => {
  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Skills
        </Typography>
        {Object.keys(skills).map(category => (
          <Box key={category} mb={4}>
            <Box display="flex" alignItems="center" mb={2}>
              {categoryIcons[category]}
              <Typography variant="h6" component="h2" gutterBottom style={{ marginLeft: 8 }}>
                {category}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {skills[category].map(skill => (
                <Grid item xs={12} sm={6} md={4} key={skill}>
                  <List>
                    <ListItem>
                      <ListItemText primary={skill} />
                    </ListItem>
                  </List>
                </Grid>
              ))}
            </Grid>
            <Divider />
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default Skills;
