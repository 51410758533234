import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeNavBar from './components/HomeNavBar';
import Home from './pages/Home';
import Skills from './pages/Skills';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import darkTheme from './theme';

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
    <Router>
      <HomeNavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/projects" element={<div>Projects</div>} />
        <Route path="/contact" element={<div>Contact</div>} />
      </Routes>
    </Router>
    </ThemeProvider>

  );
}

export default App;
